import * as React from 'react';

type Props = {
  className?: string;
};

const HeadsetProServIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="24px"
    height="24px"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2448 8.5279C10.4505 8.5279 9.68189 8.39884 8.96516 8.16651C8.73907 8.08898 8.48718 8.14713 8.31283 8.32075L7.29894 9.59322C5.47133 8.72147 3.75985 7.07487 2.84934 5.18266L4.10864 4.11069C4.28299 3.92986 4.33474 3.67758 4.26366 3.45162C4.02472 2.73494 3.902 1.96647 3.902 1.17199C3.902 0.823342 3.61142 0.532844 3.26263 0.532844H1.02821C0.679512 0.532844 0.25974 0.687648 0.25974 1.17199C0.25974 7.1718 5.25173 12.1575 11.2448 12.1575C11.7033 12.1575 11.8841 11.7507 11.8841 11.3952V9.16703C11.8841 8.81838 11.5935 8.5279 11.2448 8.5279Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2448 8.5279C10.4505 8.5279 9.68189 8.39884 8.96516 8.16651C8.73907 8.08898 8.48718 8.14713 8.31283 8.32075L7.29894 9.59322C5.47133 8.72147 3.75985 7.07487 2.84934 5.18266L4.10864 4.11069C4.28299 3.92986 4.33474 3.67758 4.26366 3.45162C4.02472 2.73494 3.902 1.96647 3.902 1.17199C3.902 0.823342 3.61142 0.532844 3.26263 0.532844H1.02821C0.679512 0.532844 0.25974 0.687648 0.25974 1.17199C0.25974 7.1718 5.25173 12.1575 11.2448 12.1575C11.7033 12.1575 11.8841 11.7507 11.8841 11.3952V9.16703C11.8841 8.81838 11.5935 8.5279 11.2448 8.5279Z"
      fill="black"
    />
  </svg>
);

export default HeadsetProServIcon;
